import React, {useState, useEffect, useRef} from 'react';
import App from 'next/app';
import Head from 'next/head';
import {useRouter} from 'next/router';
import {WPFetch} from 'aac-components/utils/fetch';
import menuItemCreator from '../lib/actions/menuItemCreator';
import AppContext from '../components/AppContext';
import HiddenOnMobile from '../components/HiddenOnMobile';
import AdminBar from 'aac-components/components/AdminBar';
import {initialAppState} from '../components/AppContext';
import StickyFooterContext, {
    initialStickyFooterState,
} from '../components/StickyFooter/StickyFooterContext';
import SeoHead from '../components/SeoHead';
import BreadcrumbSchema from 'aac-components/components/Schema/BreadcrumbSchema';
import CustomPageSchema from 'aac-components/components/Schema/CustomPageSchema';
import {debounce} from 'aac-components/utils/throttle';
import {getDynamicKeywords} from '../lib/dynamic-keywords';
import {getIpAddress} from 'aac-components/utils/getIpAddress';
import GeoTargetTabWrapper from '../components/GeoTargetTabWrapper';
import {getCookie} from 'aac-components/utils/helpers';
import {useWpFetch} from '../lib/hooks/useFetch';
import {getMatchFacility} from 'aac-components/components/GeoTargetDrawerContent/helpers';
import {
    lazyLoadImagesClientside,
    loadStateFromLocalStorage,
    saveStateToLocalStorage,
    getSwapNumber,
    getPageLang,
    updateHtmlLang,
    getSegmentNumber,
    getSessionStorageState,
} from '../lib/utils';
import Drift from '../components/Drift';
import DriftContext from '../components/Drift/DriftContext';
import GetHelpTab from '../components/GetHelpTab';

export default function MyApp(props) {
    const {Component, pageProps = {}, ipAddress, blockingData = {}} = props;

    // drift state
    const [driftLoaded, setDriftLoaded] = useState(false);
    const [isChatActive, setIsChatActive] = useState(false);

    // sticky footer state
    const [stickyFooterState, setStickyFooterState] = useState(initialStickyFooterState);
    const updateStickyFooterState = (newState) => {
        setStickyFooterState((prevState) => ({
            ...prevState,
            ...newState,
        }));
    };

    // router
    const router = useRouter();
    const {asPath, query} = router;
    const clearCache = Boolean(query?.clearCache);

    // refs
    const pagePropsRef = useRef(pageProps);
    const asPathRef = useRef(asPath);
    const liftAiRef = useRef(0);

    // state
    const [mounted, setMounted] = useState(false);
    const [state, setState] = useState(initialAppState);
    const updateState = (newState) => {
        setState((prevState) => ({
            ...prevState,
            ...newState,
        }));
    };

    // Request from LiftAi team to trigger this event on page change
    useEffect(() => {
        if (window && window.drift && window.drift.page) {
            window?.drift?.page(asPath);
            console.log(`drift.page(${asPath}) called`);
        }
    }, [asPath]);

    /**
     *  Get ACF Options clientside to reduce data size in getInitial Props
     */
    const {data: acfOptions = {}} = useWpFetch(
        '/wordpress/acf/v3/options/options',
        clearCache,
    );
    useEffect(() => {
        updateState({acfOptions: acfOptions || {}});
    }, [acfOptions]);

    /**
     * Updates state.isMobile if the user has resized the viewport over/under 992px
     */
    const reportWindowSize = () => {
        const isMobile = window && window.innerWidth <= 992;
        updateState({isMobile});
    };

    /**
     * Merge history array into state from localStorage on any serverside navigation
     * Not necessary on clientside navigations, although you could
     */
    useEffect(() => {
        const localStorageState = loadStateFromLocalStorage(state);

        if (typeof window !== 'undefined') {
            // images lazyload
            lazyLoadImagesClientside();

            // page lang
            const lang = getPageLang(asPath);
            window && lang == 'es' && updateHtmlLang(lang);
        }

        updateState({...localStorageState});
    }, [asPath]);
    /**
     * Merge blockingData into state (menuItems)
     */
    useEffect(() => {
        updateState({...blockingData});
    }, [blockingData]);

    /**
     * Session storage for VET phone usage
     */
    useEffect(() => {
        const vetPhoneTargets = ['veteran', 'tricare', 'va-benefits', 'first-responder'];
        const isVetPhoneTarget =
            vetPhoneTargets?.filter((target) => asPath?.includes(target))?.length >= 1;
        const isVetSessionSet = getSessionStorageState()?.useVetPhone;

        if (isVetPhoneTarget && !isVetSessionSet) {
            const sessionStorageState = getSessionStorageState();
            window?.sessionStorage?.setItem(
                'AacApp',
                JSON.stringify({useVetPhone: true, ...sessionStorageState}),
            );
        }
    }, [asPath]);

    /**
     * Updates needed for when user changes pages (serverside or clientside navigation)
     */
    useEffect(() => {
        // scroll to the top after clientside navigation
        document.body.scrollTop = 0;
        // lazyload images not using next/image
        if (window?.lazyLoadInstance) {
            lazyLoadInstance?.update();
        }

        // record window size
        window &&
            (reportWindowSize(),
            window.addEventListener('resize', debounce(reportWindowSize, 500)));
        const callTrackingNumber = getSwapNumber(asPath, pageProps);
        const dynamicKeywords = getDynamicKeywords(asPath);

        // segment
        const segmentName = pageProps?.data?.acf?.content_segment || '';

        // get sessionStorage data
        const sessionStorageState = getSessionStorageState(state.sessionStorageState);

        // update state.history with new slug user visits
        setState((prevState) => {
            const newState = {
                ...prevState,
                callTrackingNumber,
                dynamicKeywords,
                segment: {
                    name: segmentName,
                    number: getSegmentNumber(segmentName),
                },
                history: [prevState.history.slice(-1)[0], asPath],
            };

            // ensures merging sessionStorage to state only happens once
            if (!mounted) {
                newState.sessionStorageState = sessionStorageState;
            }

            return newState;
        });

        // update refs with new values
        pagePropsRef.current = pageProps;
        asPathRef.current = asPath;
    }, [asPath]);

    /**
     * componentDidMount
     * Subscribe to LiftAIScoreChanged event
     */
    useEffect(() => {
        setMounted(true);

        const handleLiftAIScoreChange = (event) => {
            let liftAiScore = event?.detail?.value;
            liftAiScore = Number(liftAiScore.split('-')?.[0]);

            // if score is different than previous score. This listener seems to run a few times after score change
            if (liftAiScore !== liftAiRef.current) {
                // callrail number
                const callTrackingNumber = getSwapNumber(
                    asPathRef.current,
                    pagePropsRef.current,
                    liftAiScore,
                );
                updateState({callTrackingNumber, liftAiScore});

                // heap
                window?.heap &&
                    window?.heap?.track('Lift AI Score Change', {
                        score: event?.detail?.value, // the range
                    });
                liftAiRef.current = liftAiScore;
            }
        };

        // add listener for change
        document.addEventListener('LiftAIScoreChanged', handleLiftAIScoreChange);
        return () =>
            document.removeEventListener('LifAIScoreChanged', handleLiftAIScoreChange);
    }, []);

    useEffect(() => {
        window && window?.CallTrk?.swap();
    }, [state?.callTrackingNumber?.href]);

    // update localStoreage when state changes
    useEffect(() => {
        // save new state to localStorage so we can track user history (we only save state.history & maybe state.dynamicKeywords)
        saveStateToLocalStorage(state);
    }, [state]);

    /**
     * Helper function to update sessionStorage
     */
    const setSessionStorageKey = (keyId, value) => {
        const sessionStorageState = {
            ...state.sessionStorageState,
            [keyId]: value,
        };
        window &&
            window.sessionStorage &&
            window.sessionStorage.setItem('AacApp', JSON.stringify(sessionStorageState));
        updateState({sessionStorageState});
    };
    const saveGeolocationToCookie = (data) => {
        // max-age 1 year (365*60*60*34)
        document.cookie = `aacAppState=${encodeURIComponent(
            JSON.stringify(data),
        )}; max-age=31536000; path=/`;
    };

    // geo location
    const getUserGeoLocation = async () => {
        // ::1 is value for local env
        if (!ipAddress || ipAddress === '::1') return;

        try {
            const response = await fetch('/cp-aac/api/geolocation', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                    'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
                },
                body: JSON.stringify({ipAddress}),
            });
            const geolocation = await response.json();
            let geolocationWithMatch = {};
            if (geolocation?.data) {
                // Check if user is within distance of a facility
                const matchFacility = getMatchFacility(geolocation?.data);
                geolocationWithMatch = {
                    ...geolocation?.data,
                    facility: matchFacility?.siteKey,
                    distance: matchFacility?.distance,
                };
            }
            saveGeolocationToCookie({
                facility: geolocationWithMatch?.facility,
                distance: geolocationWithMatch?.distance,
            });
            setSessionStorageKey(
                'geolocation',
                geolocation?.data ? geolocationWithMatch : geolocation,
            );
        } catch (error) {
            console.log(error);
        }
    };
    /**
     * Get user's geolocation based off their IP
     */
    useEffect(() => {
        // Fetch user's geolocation data only if it wasn't already fetched
        // We have to grab data directly from window.sessionStorage here
        const sessionStorageState = getSessionStorageState();
        if (Object.keys(sessionStorageState?.geolocation || {}).length === 0) {
            getUserGeoLocation();
        }
    }, [ipAddress]);

    const {
        data: {
            id = 0,
            acf: {aac_json_schema = '', content_segment = ''} = {},
            tags = [],
        } = {},
    } = pageProps;
    const {menuItems, footerMenuItems, isLoggedIn} = blockingData;

    const tagNames = tags.map((tag) => {
        return tag.name;
    });
    const tagString = tagNames.join(', ');

    return (
        <>
            <Head>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, minimal-ui"
                />
                <meta property="article:tag" content={tagString}></meta>
            </Head>
            <SeoHead {...pageProps} />
            <BreadcrumbSchema />
            <CustomPageSchema schema={aac_json_schema} />
            <div id="app__root">
                <AppContext.Provider value={{...state, menuItems, pageProps}}>
                    <DriftContext.Provider
                        value={{
                            driftLoaded,
                            setDriftLoaded,
                            isChatActive,
                            setIsChatActive,
                        }}>
                        <StickyFooterContext.Provider
                            value={{stickyFooterState, updateStickyFooterState}}>
                            <Drift />
                            <div
                                id="page-segment"
                                className={content_segment}
                                data-post-id={id}
                                data-segment={content_segment}
                                data-segment-number={getSegmentNumber(content_segment)}
                                data-tags={tagString}
                            />
                            {isLoggedIn && (
                                <HiddenOnMobile>
                                    <AdminBar
                                        pageProps={pageProps}
                                        loggedInUser={blockingData?.loggedInUser}
                                    />
                                </HiddenOnMobile>
                            )}
                            <Component {...pageProps} />
                        </StickyFooterContext.Provider>
                    </DriftContext.Provider>
                </AppContext.Provider>
            </div>
        </>
    );
}
/**
 * Execute any code that should run before the Application is rendered
 * @param {Object} appContext
 */
MyApp.getInitialProps = async (appContext) => {
    // get your appProps, needed per next.js docs https://nextjs.org/docs/advanced-features/custom-app
    const appProps = await App.getInitialProps(appContext);

    // See if the user is logged into the wordpress admin dashboard
    const {ctx = {}} = appContext;
    const settings =
        (ctx?.req?.headers?.cookie &&
            ctx.req.headers.cookie
                .split('; ')
                .find((row) => row.startsWith('wordpress_logged_in_'))) ||
        false;
    const isLoggedIn = settings !== false;
    const loggedInUser = (settings && settings?.split('=')?.[1]?.split('%'))?.[0] || '';
    const clearCache = Boolean(ctx?.req?.query?.clearCache);

    // Get user's IP
    const {req} = ctx;
    //const ipAddress = getIpAddress(req);
    const ipAddress = req?.query?.ipAddress || getIpAddress(req);

    // Get our blocking data. Menus and global ACF site options
    const [menuItems = [], footerMenuItems = []] = await Promise.all(
        ['/wordpress/aac/v1/menu/main-menu', '/wordpress/aac/v1/menu/footer'].map((url) =>
            WPFetch(url, clearCache)
                .then(({data}) => {
                    if (Array.isArray(data)) {
                        return data.map(menuItemCreator);
                    }
                    return data;
                })
                .catch((error) => console.log(error)),
        ),
    );

    return {
        ...appProps,
        ipAddress,
        blockingData: {
            menuItems,
            footerMenuItems,
            isLoggedIn,
            loggedInUser,
        },
    };
};
