import {zIndex} from 'aac-components/utils/helpers';
import {useContext, useState, useEffect} from 'react';
import {GetHelpModalContentDesktop} from '../GetHelpModalContent';
import AppContext from '../AppContext';
import Modal from 'aac-components/components/Modal';
import SharedModalContent from '../GetHelpModalContent/SharedModalContent';
import IconClose from 'aac-components/components/Icons/Basic/IconClose';

const GetHelpTab = () => {
    const {isMobile} = useContext(AppContext);
    const [showModal, setShowModal] = useState(false);

    const [showTab, setShowTab] = useState(false);
    const [showCloseButton, setShowCloseButton] = useState(false);

    // on mount, grab sessionStorage value and check if tab has been dismissed
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const AacApp = window?.sessionStorage?.getItem?.('AacApp')
                ? JSON.parse(window?.sessionStorage?.getItem?.('AacApp'))
                : null;
            if (!AacApp?.hideGetHelpTab) {
                setShowTab(true);
            }
        }
    }, []);

    useEffect(() => {
        // swap callrail number
        if (typeof window !== 'undefined' && window.CallTrk) {
            window.CallTrk.swap();
        }
    }, [showModal]);

    const hideGetHelpTab = () => {
        if (showCloseButton && typeof window !== 'undefined') {
            const AacApp = window?.sessionStorage?.getItem?.('AacApp')
                ? JSON.parse(window?.sessionStorage?.getItem?.('AacApp'))
                : null;
            const newStorage = {
                ...AacApp,
                hideGetHelpTab: true,
            };
            sessionStorage.setItem('AacApp', JSON.stringify(newStorage));
            setShowTab(false);
        }
    };

    if (!showTab) return null;

    return (
        <div className="get-help-tab">
            {showCloseButton && (
                <div className="get-help-tab__close" onClick={hideGetHelpTab}>
                    <IconClose fill="#fff" width="14" height="14" />
                </div>
            )}
            <div
                className="get-help-tab__button"
                onClick={() => {
                    setShowModal(true);
                    setShowCloseButton(true);
                }}>
                <div className="get-help-tab__title">Get Help</div>
            </div>
            {showModal && (
                <Modal showModal={showModal}>
                    {isMobile ? (
                        <div className="modal-content__mobile">
                            <div
                                className="modal-close__mobile"
                                onClick={() => setShowModal(false)}>
                                <IconClose fill="#fff" />
                            </div>
                            <SharedModalContent />
                        </div>
                    ) : (
                        <GetHelpModalContentDesktop setShowModal={setShowModal} />
                    )}
                </Modal>
            )}

            <style jsx>{`
                .get-help-tab {
                    position: fixed;
                    top: 35%;
                    right: 0;
                    height: 150px;
                    display: flex;
                    align-items: flex-start;
                    z-index: ${zIndex('floatingActionButtonOpen')};
                }
                .get-help-tab__button {
                    background: var(--interactive);
                    color: #fff;
                    width: 40px;
                    margin-top: 20px;
                    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
                        0px 1px 2px rgba(0, 0, 0, 0.3);
                    right: 0;
                    height: 100px;
                    border-radius: 4px 0px 0px 4px;
                    cursor: pointer;
                    pointer-events: auto;
                }
                .get-help-tab__title {
                    color: #fff;
                    font-weight: bold;
                    transform: rotate(-90deg);
                    height: 40px;
                    width: 200px;
                    position: absolute;
                    left: -70px;
                    top: -15px;
                }
                .modal-content__mobile {
                    max-height: 100vh;
                    overflow-y: scroll;
                }
                .modal-close__mobile {
                    position: fixed;
                    right: 8px;
                    top: 8px;
                }
                .get-help-tab__close {
                    position: fixed;
                    right: 33px;
                    top: 36%;
                    background: var(--gray-500);
                    border-radius: 100%;
                    height: 20px;
                    width: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
            `}</style>
        </div>
    );
};
export default GetHelpTab;
