import React from 'react';
import IconArrowShort from 'aac-components/components/Icons/Arrow/IconArrowShort';
import CallrailLink from '../CallrailLink';
import Link from 'next/link';
import IconClipboard from 'aac-components/components/Icons/Misc/IconClipboard';
import IconMobile from 'aac-components/components/Icons/Media/IconMobile';
import {isMappableArray} from 'aac-components/utils/helpers';
import IconLocation from 'aac-components/components/Icons/Basic/IconLocation';
import IconCreditCard from 'aac-components/components/Icons/Misc/IconCreditCard';
import IconShield from 'aac-components/components/Icons/Basic/IconShield';
import IconHomeCheck from 'aac-components/components/Icons/Building/IconHomeCheck';
import {BREAKPOINT} from 'aac-components/utils/styles';

const SharedModalContent = () => {
    return (
        <div className="container">
            <div>
                <h3>We Are Available 24/7</h3>
                <div style={{marginBottom: '24px'}}>
                    <CallrailLink
                        style={{
                            fontSize: '24px',
                            fontWeight: 'bold',
                        }}
                    />
                </div>
                <p>
                    When you call us, we’ll take the time to understand your current
                    situation. From your first call with us to your first day of
                    treatment, our admissions navigators will guide you every step of the
                    way.
                </p>
                <ModalLinks
                    links={[
                        {
                            href: '/not-ready-to-talk',
                            title: 'Not Ready Talk? Text Us Instead',
                            Icon: IconMobile,
                        },
                        {
                            href: '/self-assessment',
                            title: 'Take Our Self-Assessment Quiz',
                            Icon: IconClipboard,
                        },
                    ]}
                />
            </div>
            <div>
                <h3>Know Before You Go</h3>
                <p>
                    American Addiction Centers (AAC) customizes treatment for each
                    individual, considering their specific needs. Your personalized plan
                    will determine the exact costs.
                </p>
                <ModalLinks
                    links={[
                        {
                            href: '/treatment-centers',
                            title: 'Our Locations',
                            Icon: IconLocation,
                        },
                        {
                            href: '/payment-options',
                            title: 'Payment Options',
                            Icon: IconCreditCard,
                        },
                        {
                            href: '/verify-insurance',
                            title: 'Check My Insurance',
                            Icon: IconShield,
                            iconInnerFill: 'var(--gray-500)',
                        },
                    ]}
                />
            </div>
            <div>
                <h3>Admissions</h3>
                <p>
                    The admissions process can seem daunting at first, but we'll be with
                    you every step of the way to answer any questions and make you feel as
                    comfortable as possible.
                </p>
                <ModalLinks
                    links={[
                        {
                            href: '/admissions',
                            title: 'Admission Process',
                            Icon: IconHomeCheck,
                        },
                        {
                            href: '/admissions/navigator-team',
                            title: 'Meet Our Admissions Team',
                            Icon: IconCreditCard,
                        },
                    ]}
                />
            </div>
            <style jsx>
                {`
                    h3 {
                        margin-bottom: 24px;
                    }
                    p {
                        margin: 0 0 24px 0;
                    }
                    .container {
                        color: #fff;
                        background: var(--gray-500);
                        padding: 80px 15px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .container {
                            padding: 0;
                        }
                    }
                `}
            </style>
        </div>
    );
};
export default SharedModalContent;

export const ModalLinks = ({links = []}) => {
    return (
        <div className="link__container">
            {isMappableArray(links) &&
                links.map((link) => {
                    const {
                        href = '/',
                        title = '',
                        Icon,
                        iconFill = '#fff',
                        iconInnerFill = 'none',
                    } = link;
                    return (
                        <React.Fragment key={href}>
                            <Link
                                href={href}
                                title={title}
                                style={{
                                    color: '#fff',
                                    textDecoration: 'none',
                                    textTransform: 'uppercase',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                }}>
                                <div className="modal__link">
                                    <Icon fill={iconFill} innerFill={iconInnerFill} />
                                    <span dangerouslySetInnerHTML={{__html: title}} />
                                    <IconArrowShort
                                        fill="var(--interactive-300)"
                                        rotate="-90"
                                    />
                                </div>
                            </Link>
                        </React.Fragment>
                    );
                })}
            <style>
                {`
                .modal__link {
                    padding: 4px 0;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    grid-gap: 8px;
                    border-bottom: 1px solid var(--gray-400);

                }
                .link__container {
                    border-top: 1px solid var(--gray-400);
                    max-width: fit-content;
                    margin-bottom: 40px;
                }
                `}
            </style>
        </div>
    );
};
